import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Loading",
  code: "Loading"
};
export const Wrapper = ({
  children
}) => {
  return <div className="bg-bg-primary px-4 py-2 rouned-2 flex items-center justify-center">{children}</div>;
};
export const _frontmatter = {
  "menuLabel": "Loading",
  "sortOrder": 12.3
};
const layoutProps = {
  componentName,
  Wrapper,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Loading`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-loading--default-story" wrapper={Wrapper} wrapperCond={["Inverse"]} mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Loading } from "@caesars-digital/caesars-ui";
`}</code></pre>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10">
  <span className="text-red-600">*</span> indicates required.
    </div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
    The Loading component provides a clear visual cue, indicating that a process or action is in progress. It utilizes appropriate animations or visual elements, such as spinners or dots, to convey the loading state. The size and color of the loading indicator are adjustable based on the provided properties.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
   The Loading component itself is not directly operable, as it is a visual cue rather than an interactive element. However, it should not interfere with the operability of other elements on the page, ensuring users can still interact with the available content while the loading indicator is displayed.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The purpose of the Loading component is clear, visually indicating that a process or action is ongoing. The type of loading indicator (dots, spinner, etc.) and its associated size and color variant can be customized based on the provided properties. Additionally, the option to include a label underneath the Loading Spinner provides additional context or information to users.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="ready">
    The Loading component is designed to be compatible with assistive technologies, ensuring that users relying on screen readers or other accessibility tools are aware of the loading state. It functions consistently across different browsers and platforms. The Loading component can be easily integrated into various parts of the application to provide a consistent loading experience
  </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      